<template>
	<div>
		<Header :title="isEdit ? '编辑卖品券' : '新增卖品券'" @back="onBack(false)"></Header>
		<a-spin :spinning="loading">
			<a-form style="margin-top: 20px;" ref="form" name="form" :model="modelRef" scrollToFirstError
				:label-col="{ span: 6 }" :wrapper-col="{ span: 16 }" @finish="onSubmit">

<!--				<a-form-item class="ui-form__item" name="organizationId" label="影院组织" :rules="[{ required: true, message: '必选项不允许为空' }]">-->
<!--					<a-select :disabled="isSee || isEdit" placeholder="请选择" v-model:value="modelRef.organizationId" @change="(id) => getAllCinemaList(id)">-->
<!--						<a-select-option v-for="item in organizationList" :vlaue="item.id" :key="item.id"> {{ item.name }}</a-select-option>-->
<!--					</a-select>-->
<!--				</a-form-item>-->

<!--				<a-form-item class="ui-form__item" name="cinemaId" label="所属影院" :rules="[{ required: true, message: '必选项不允许为空' }]">-->
<!--					<a-select :disabled="isSee || isEdit" placeholder="请选择" v-model:value="modelRef.cinemaId" @change="onSnackClear">-->
<!--						<a-select-option v-for="item in cinemaList" :vlaue="item.id" :key="item.id"> {{ item.name }}</a-select-option>-->
<!--					</a-select>-->
<!--				</a-form-item>-->
        <a-form-item label="是否一券通" name="isOneVoucher" :rules="[{required: true, message: '必填项不允许为空'}]">
          <a-radio-group v-model:value="modelRef.isOneVoucher" :disabled="isSee">
            <a-radio :value="1">
              是
            </a-radio>
            <a-radio :value="0">
              否
            </a-radio>
          </a-radio-group>
        </a-form-item>
        <a-form-item label="可用影院" name="organizationId" v-if="modelRef.isOneVoucher == 0">
          <a-form-item-rest>
            <!--          <a-button @click="onSelectCinema" type="primary">选择影院</a-button>-->
            <selectCinema :disabled="isSee" v-model:value="modelRef.cinemaIds" :isEdit="isEdit" :isSee="isSee" :selectItem="cinemaTableList" @change="(dataList) => { cinemaTableList = dataList; }"></selectCinema>
          </a-form-item-rest>
          <div v-if="cinemaTableList.length" style="margin: 10px 0;">
            <a-table rowKey="id" :columns="cinemaColumns" :dataSource="cinemaTableList" size="small" :pagination="false">
              <template #bodyCell="{ column, record, index }">
                <template v-if="column.key === 'action'">
                  <a-button :disabled="isSee" type="link" @click="onDeleteCinema(record, index)">删除</a-button>
                </template>
              </template>
            </a-table>
          </div>
        </a-form-item>

				<a-form-item label=" 卖品券批次号" name="batchNo" :rules="[{ required: true, message: '必填项不允许为空' }]">
					<a-input :disabled="isSee || isEdit" v-model:value="modelRef.batchNo" placeholder="请输入" />
				</a-form-item>

				<a-form-item label=" 卖品券批次号名称" name="name" :rules="[{ required: true, message: '必填项不允许为空' }]">
					<a-input :disabled="isSee" v-model:value="modelRef.name" placeholder="请输入" />
				</a-form-item>

				<a-form-item label="生成数量" name="generateNum" :rules="[{ required: true, message: '必填项不允许为空' }]" extra="最大可生成1万条数据">
					<a-input-number :disabled="isSee || isEdit" v-model:value="modelRef.generateNum" :min="0" :max="10000" :precision="0" placeholder="请输入" />
				</a-form-item>

				<a-form-item label="有效期" :rules="[{required: true, message: '必填项不允许为空'}]">
					<div style="display: flex;align-items: center;">
						<div v-if="[7].includes(modelRef.validPeriodType)" style="padding-right: 8px;">券领取成功后</div>
						<a-input-number :disabled="isSee || isEdit" v-model:value="modelRef.period" v-if="[7].includes(modelRef.validPeriodType)" :min="0" :precision="0" placeholder="请输入"></a-input-number>
						<a-select :disabled="isSee || isEdit" style="width: 180px;" v-model:value="modelRef.validPeriodType" placeholder="请选择">
							<a-select-option :value="7">日</a-select-option>
							<a-select-option :value="0">指定时间</a-select-option>
						</a-select>
						<div v-if="[7].includes(modelRef.validPeriodType)" style="padding-left: 8px;">内有效</div>
					</div>
				</a-form-item>

				<a-form-item v-if="modelRef.validPeriodType === 0" label="指定时间内有效" name="time" :rules="[{required: true, message: '必填项不允许为空'}]">
					<a-range-picker :disabled="isSee || isEdit" v-model:value="modelRef.time"/>
				</a-form-item>
				<!-- <a-form-item label="有效期" name='time' :rules="[{required: true, message: '必填项不允许为空'}]">
					<a-range-picker :disabled="isSee || isEdit" v-model:value="modelRef.time"/>
				</a-form-item> -->

<!--				<a-form-item label="可兑换卖品" >-->
<!--					<snackModal v-if="modelRef.cinemaId" v-model:value="snackIds" :selectItem="snackList" :id="modelRef.cinemaId" @change="onSnackChange" :snackCinema="modelRef" snackType="snack" :organizationId="modelRef.organizationId"></snackModal>-->
<!--					<a-button v-else disabled>请选择所属影院</a-button>-->

<!--					<a-form-item-rest v-if="snackList.length">-->
<!--						<a-table :dataSource="snackList" :columns="snackColumns" rowKey="id" :pagination="false">-->
<!--							<template #bodyCell="{ column, record, index }">-->
<!--								<template v-if="column.key === 'img'">-->
<!--									<a-image style="width: 40px;" :src="record.imgUrl"></a-image>-->
<!--								</template>-->
<!--								<template v-if="column.key === 'card'">-->
<!--									<div v-if="record.isCard">-->
<!--										<a-tag color="orange">{{ ['次卡', '年卡'][record.cardType - 1] }}</a-tag>-->
<!--										<div>卡次数：{{ record.cardBalanceTimes }}</div>-->
<!--										<div>卡等级：{{ record.cardTitle || '' }}</div>-->
<!--									</div>-->
<!--									<div v-else>&#45;&#45;</div>-->
<!--								</template>-->
<!--								<template v-if="column.key === 'action'">-->
<!--									<a-button type="link" @click="onDelete(record, index)">删除</a-button>-->
<!--								</template>-->
<!--							</template>-->
<!--						</a-table>-->
<!--					</a-form-item-rest>-->
<!--				</a-form-item>-->

				<a-form-item label="是否启用" name="isDisabled" :rules="[{ required: true, message: '必填项不允许为空' }]">
					<a-radio-group :disabled="isSee" v-model:value="modelRef.isDisabled">
						<a-radio :value="0">启用</a-radio>
						<a-radio :value="1">禁用</a-radio>
					</a-radio-group>
				</a-form-item>

				<a-form-item label="是否用于商品卖品线上会员卡充值发放" name="isGrant">
					<a-radio-group v-model:value="modelRef.isGrant" :disabled="isSee || isEdit">
						<a-radio :value="1">是</a-radio>
						<a-radio :value="0">否</a-radio>
					</a-radio-group>
          <div style="margin-top: 10px;color: red;">
            <div>注意：</div>
            <div>选择是，用户无法在小程序进行券的绑定，此批券只能商品和卖品进行绑定用户购买后自动发放至账户；</div>
            <div>选择否，小程序可以进行券绑定，如果需要批量生成让用户自行绑定，请选择否；</div>
            <div>新增券后无法编辑。</div>
          </div>
				</a-form-item>

				<a-form-item label="使用规则" name="useRule" :rules="[{ required: true, message: '必填项不允许为空' }]">
					<a-textarea :disabled="isSee" v-model:value="modelRef.useRule" placeholder="请输入使用规则,换行需要输入'|'字符"></a-textarea>
				</a-form-item>

				<a-form-item>
					<div style="text-align: center;">
						<a-button type="primary" v-if="!isSee" html-type="submit">提交</a-button>
						<a-button style="margin-left: 20px;" @click="onBack(false)">返回</a-button>
					</div>
				</a-form-item>
			</a-form>
		</a-spin>
	</div>
</template>

<script>
	import Header from '@/components/header/header.vue';
	import snackModal from '@/components/snackSelect/index.vue';
	import { getOrganizationList, getCinemaList } from '@/service/modules/cinema.js';
	import { generateCoupon, couponBatchDetail, updateAndSync } from '@/service/modules/coupon.js';
  import selectCinema from "@/components/selectCinema/index.vue";
	export default {
		components: { selectCinema, Header, snackModal},
		props: {
			isEdit: {
				type: Boolean,
				default: false
			},
			isSee: {
				type: Boolean,
				default: false
			},
			id: {
				type: Number,
				default: 0
			}
		},
		data() {
			return {
				loading: false,
				modelRef: {
					isDisabled: 0,
					isGrant: 0,
          isOneVoucher:1,//是否一卡通,
          cinemaIds:[],//可用影院列表
				},
        cinemaTableList:[],//可用影院表格列表
        cinemaColumns:[//可用影院表格表头
          {
            title: '影院编码',
            dataIndex: 'code'
          },
          {
            title: '影院名称',
            dataIndex: 'name'
          },
          {
            title: '操作',
            key: 'action'
          }
        ],
				organizationList: [],
				cinemaList: [],
				snackIds: [],
				snackList:[],
				snackColumns: [{
					title: '卖品名称',
					dataIndex: 'mainTitle'
				}, {
					title: '卖品图片',
					key: 'img'
				}, {
					title: '卡信息',
					key: 'card'
				}, {
					title: '操作',
					key: 'action',
					width: 100
				}]
			}
		},
		created() {
			this.getOrganizationList();
			if (this.id) {
				this.getCouponBatchDetail();
			}
		},
		methods: {
			onBack(isRef) {
				this.$emit('back', isRef);
			},
			async onSubmit() {
        if (this.modelRef.isOneVoucher == 0){
          if(this.cinemaTableList.length == 0){
            this.$message.warn('请选择可用影院');
            return;
          }

        }
				// if (this.snackIds.length === 0) {
				// 	return this.$message.warn('请选择可兑换卖品');
				// }
				this.loading = true;
				let postData = JSON.parse(JSON.stringify(this.modelRef));
				delete postData.time;
				postData.couponType = 3;
				postData.useLimit = 5;
				postData.goodsIds = this.snackIds.join(',');
        // postData.cinemaIds ? postData.cinemaIds.join(','):''
        let cinemaNameList = []
        let cinemaIdsList = []
        if (this.cinemaTableList.length!=0){
          this.cinemaTableList.forEach(item=>{
            if (item.id){
              cinemaIdsList.push(item.id)
            }
            if (item.name){
              cinemaNameList.push(item.name)
            }
          })
        }
        postData.cinemaIds = cinemaIdsList?cinemaIdsList.toString():''
        postData.cinemaNames = cinemaNameList?cinemaNameList.toString():''
				if (postData.validPeriodType === 0) {
					postData.period = '';
					postData.receiveStartTime = parseInt(this.modelRef.time[0].startOf('day').valueOf() / 1000);
					postData.receiveEndTime = parseInt(this.modelRef.time[1].endOf('day').valueOf() / 1000);
				}
				let ret;
				if (this.isEdit) {
					ret = await updateAndSync(postData);
				} else {
					ret = await generateCoupon(postData);
				}
				this.loading = false;
				if (ret.code === 200) {
					// this.$message.success('新增【' + postData.batchNo + '】卖品券成功');
					if (this.isEdit) {
						this.$message.success('编辑【' + postData.batchNo + '】卖品券成功');
					} else {
						this.$message.success('任务创建成功，请在任务中心-任务列表查看完成情况');
					}
					this.onBack(true);
				}
			},
			async getCouponBatchDetail() {
				this.loading = false;
				try {
					let ret = await couponBatchDetail({
						id: this.id
					});
					this.loading = false;
					if (ret.code === 200) {
						ret.data.generateNum = ret.data.importCount;
						ret.data.time = [this.moment(ret.data.receiveStartTime * 1000), this.moment(ret.data.receiveEndTime * 1000)];
						this.getAllCinemaList(ret.data.organizationId);
            ret.data.cinemaIds = ret.data.cinemaIds ? ret.data.cinemaIds.split(','): [];
            this.modelRef = ret.data;
						if (ret.data.storeGoodsStocks && ret.data.storeGoodsStocks.length) {
              const snackIds = [];
              ret.data.storeGoodsStocks.forEach(item=>{
                if (item) this.snackList.push(item);
                snackIds.push(item.id)
              })
              this.snackIds = snackIds;
						}
						delete this.modelRef.storeGoodsStocks;
						delete this.modelRef.goodsIds;
					}
				} catch(e) {
					console.log(e);
					this.loading = false;
				}
			},
			async getOrganizationList() {
				let ret = await getOrganizationList({
					page: 1,
					pageSize: 999999
				});
				if (ret.code === 200) {
					this.organizationList = ret.data.list;
				}
			},
			async getAllCinemaList(organizationId) {
				this.cinemaAllList = [];
				// this.modelRef.cinemaId = undefined;
				this.onSnackClear();
				let ret = await getCinemaList({
					page: 1,
					pageSize: 999999,
					organizationId: organizationId ? organizationId : undefined
				})
				if (ret.code === 200) {
					this.cinemaList = ret.data.list;
          //排除已选择
          if(this.isSee || this.isEdit){
            //已经使用的 hasArray
            this.cinemaTableList = this.hasArray(this.modelRef.cinemaIds,ret.data.list)
            //可以选择使用
            this.cinemaAllList = this.filterArray(this.modelRef.cinemaIds,ret.data.list)

          }
				}
			},
      filterArray(obj, arr){
        const values = Object.values(obj).map(Number); // 将对象的值转换为数字数组
        return arr.filter(item => !values.includes(item.id)); // 过滤数组，返回包含对象值的元素
      },
      hasArray(obj, arr){
        const values = Object.values(obj).map(Number); // 将对象的值转换为数字数组
        return arr.filter(item => values.includes(item.id)); // 过滤数组，返回包含对象值的元素
      },
			onSnackClear() {
				this.snackIds = [];
				this.snackList = [];
			},
			onSnackChange(list) {
				this.snackList = JSON.parse(JSON.stringify(list));
			},
			onDelete(item, index) {
				let i = this.snackIds.indexOf(item.id);
				if (i !== -1) {
					this.snackIds.splice(i, 1);
				}
				this.snackList.splice(index, 1);
			},
      onDeleteCinema(item, index) {
        let i = this.modelRef.cinemaIds.indexOf(item.id.toString());
        this.modelRef.cinemaIds.splice(i, 1);
        this.cinemaTableList.splice(index, 1);
      },
		}
	}
</script>

<style>
</style>
